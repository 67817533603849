
.header-band{
//  background: #EDF1F2;
  //padding: 15px 0 15px;
  position:relative;

  h1{
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    margin: 10px 0;
  }
  .header-band-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;
    //opacity: .2;
  }
}