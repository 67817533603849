
.search-form-vertical{
 // background: @brand-color;
  padding: 0 15px 15px 15px;
  border: 1px solid #f0f0f0;
  margin-top:20px;
  //color: #fff;
   h3{
     color: @brand-color;
   }

  .btn-primary{
    background: @brand-color;
  }
}

.homepage .search-form-vertical{
  border:none;
  padding:0 15px 0 0;
  margin-top:0;

  .form-group{
    margin-bottom: 27px;
  }
  h3{
    color: @brand-color;
    margin-bottom:30px;
    margin-top: 30px;

  }
}

.search-form-wrapper{
 // margin-bottom: 30px;



  .search-inner {
    //padding: 30px;

    position:relative;

  }

  .search-inner{

  }
}

.divider-inpagesearch{
  .search-inner .input-short{
    height: 45px;
  }
  .search-form-wrapper .search-inner button.btn{
    padding: 3px 15px;
  }

  .shortsearchform .selectize-input, .shortsearchform .selectize-control.multi .selectize-input.has-items{
    height: 45px;
  }

  .shortsearchform .selectize-input input, .shortsearchform .selectize-control.multi .selectize-input.has-items input{
    line-height: 20px !important;
  }
  .shortsearchform .aditional-search{
      height: 45px;
  }

  .search-inner .input-red.form-control {
   // background: none;
  }
}