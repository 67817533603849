.propertiesareabox{
  ul li{
    list-style:none;
    margin-bottom:3px;

    a{
      font-size: 13px;
      color: #2693FF;
    }
  }
}